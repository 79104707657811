import store from '..';
import axios from '../../plugins/axios';

export const settings_module = {
    state:()=>({
        pending: false,
        data:[],
        message: null,
        success: null,
        error: null,
        isAnnouncementPopupClose:false,
    }),
    mutations:{
        fetch_store_settings_request(state){

            state.pending = true;

        },
        fetch_store_settings_success(state, payload){
            
            state.data = payload.store;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_store_settings_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        update_remaining_space(state,payload){

            state.data.subscription.usage_space = payload;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data = [];

        },
        update_blocks(state, payload){
            
            state.data.blocks = payload;

        },
        update_banner_blocks(state, payload){

            state.data.banner_blocks = payload;
            
        },
        update_announcement_popup_close(state, payload){

            state.isAnnouncementPopupClose = payload;

        },
    },
    actions:{
        async fetchStoreSetting({commit}){
            commit("fetch_store_settings_request");
            try{
                let res = await axios.get('/getConfigure?store_id='+store.state.user.store_id);
                if(res.data.status_code == '1026'){

                    commit('fetch_store_settings_success', res.data);

                }
            }catch(ex){

                commit('fetch_store_settings_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters:{
        settings(state){

            return state.data;

        },
        isPending(state){

            return state.pending;

        },
        announcementPopupStatus(state){

            return state.isAnnouncementPopupClose;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}