<template>
  <div>
    <button v-if="!isRecording" @click.prevent="startRecording" type="button">
      <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="25px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g>
          <path d="M256,353.5c43.7,0,79-37.5,79-83.5V115.5c0-46-35.3-83.5-79-83.5c-43.7,0-79,37.5-79,83.5V270   C177,316,212.3,353.5,256,353.5z"/>
          <path d="M367,192v79.7c0,60.2-49.8,109.2-110,109.2c-60.2,0-110-49-110-109.2V192h-19v79.7c0,67.2,53,122.6,120,127.5V462h-73v18   h161v-18h-69v-62.8c66-4.9,117-60.3,117-127.5V192H367z"/>
        </g>
      </svg>
    </button>
    <template v-else>
      <!-- <h3 v-show="duration">{{ duration }}</h3> -->
      <button @click.prevent="stopRecording" type="button">
        <svg width="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM192 160H320c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32z"/>
        </svg>
      </button>
    </template>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name:"VoiceRecorder",
  data: ()=>({
    isRecording: false,
    audioBlobs: [],
    mediaRecorder: null,
    streamBeingCaptured: null,
    file: null,
    startTime: null,
    duration: null,
    interval: null,
    // fullscreenLoading: true
  }),
  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    onStart: {
      type: Function
    },
    onUpdate: {
      type: Function
    }
  },
  methods: {
    async startRecording(){

      if(!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)){
        //Feature is not supported in browser
        //return a custom error
        this.$alert('mediaDevices API or getUserMedia method is not supported in this browser.', 'Recording Error', {
          confirmButtonText: 'Ok'
        })
        return ;
        // return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
      }

      try{
        let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        this.streamBeingCaptured = stream;
        this.mediaRecorder = new MediaRecorder(this.streamBeingCaptured);
        this.audioBlobs = [];
        // console.log('Start')
        this.mediaRecorder.addEventListener("dataavailable", event => {
          //store audio Blob object
          // console.log(event)
          this.audioBlobs.push(event.data);
        });
        this.mediaRecorder.start();
        if(this.onStart){
          this.onStart();
        }
        this.startTime = new Date();
        this.isRecording = true;
        this.duration = "00:00"
        this.onUpdate(this.duration)
        if(this.interval){
          clearInterval(this.interval);
        }
        this.interval = setInterval(()=>{
          let elapsedTime = this.computeElapsedTime(this.startTime);
          this.duration = elapsedTime;
          this.onUpdate(this.duration)
        }, 1000)
        // console.log('Recording Start')
      }catch(error){

        if(error.message == 'Permission denied'){

          this.$alert('Please grant us permission to access the microphone so we can begin recording.', 'Microphone Permission denied', {
            confirmButtonText: 'Ok'
          });

        }else{

          this.$alert(error, 'Error', {
            confirmButtonText: 'Ok'
          });

        }
      }
      
    },
    stopRecording(){

      this.onSuccess();

      // console.log(this.mediaRecorder);

      let mimeType = this.mediaRecorder.mimeType.split(';')[0];

      this.mediaRecorder.addEventListener('stop', ()=>{

        let audioBlob = new Blob(this.audioBlobs, { type: mimeType });
        
        this.file = audioBlob;

        this.generateText();

        // console.log(this.file)

      });

      this.mediaRecorder.stop();

      this.streamBeingCaptured.getTracks().forEach(track  => track.stop());

      this.isRecording = false;

      this.mediaRecorder = null;

      this.streamBeingCaptured = null;

      this.interval && clearInterval(this.interval)

    },
    computeElapsedTime(startTime){
      //record end time
      let endTime = new Date();

      //time difference in ms
      let timeDiff = endTime - startTime;

      //convert time difference from ms to seconds
      timeDiff = timeDiff / 1000;

      //extract integer seconds that dont form a minute using %
      let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)

      //pad seconds with a zero if neccessary
      seconds = seconds < 10 ? "0" + seconds : seconds;

      //convert time difference from seconds to minutes using %
      timeDiff = Math.floor(timeDiff / 60);

      //extract integer minutes that don't form an hour using %
      let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
      minutes = minutes < 10 ? "0" + minutes : minutes;

      //convert time difference from minutes to hours
      timeDiff = Math.floor(timeDiff / 60);

      //extract integer hours that don't form a day using %
      let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds

      //convert time difference from hours to days
      timeDiff = Math.floor(timeDiff / 24);

      // the rest of timeDiff is number of days
      let days = timeDiff; //add days to hours

      let totalHours = hours + (days * 24);
      totalHours = totalHours < 10 ? "0" + totalHours : totalHours;

      if(totalHours === "00"){

        return minutes + ":" + seconds;

      }else{

        return totalHours + ":" + minutes + ":" + seconds;

      }

    },
    async generateText(){

      const loading = this.$loading({
        lock: true,
        text: 'We appreciate your patience while we process and acknowledge your input.',
        // spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      });
      let formData = new FormData();
      let file = new File([this.file], `audio.${this.file.type.split('/')[1]}`, {
        type: this.file.type
      })
      // console.log(file, 'file')
      formData.append('file', file);
      try{
        let res = await this.$axios.post('/ai/translate', formData)
        this.onSuccess(res.data.text);
      }catch(ex){
        let error = ex.response ? ex.response.data.message : ex.message;
        this.$message({
          type: 'error',
          showClose: true,
          message:  error,
        });
        // console.log(ex)
      }finally{
        loading.close();
      }
    }
  }
  }
</script>