import axios from '../../plugins/axios';
export const gallery_module = {
    state: ()=>({
        pending: false,
        data: [],
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_gallery_request(state){

            state.pending = true;

        },
        fetch_gallery_success(state, payload){

            state.data = payload.gallery;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_gallery_failure(state, payload){
            
            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },

        delete_gallery_item(state, payload){

            let banners = state.data.filter(gallery => gallery.id != payload.id);

            state.data = banners;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data = [];

        }
    },
    actions:{
        async fetchGalleryRequest({commit}){

            commit('fetch_gallery_request');
            try{
                let res = await axios.get('/gallery/all');
                if(res.data.status_code == '1290'){

                    commit('fetch_gallery_success', res.data);

                }
            }catch(ex){

                commit('fetch_gallery_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters: {
        gallery(state){

            return state.data;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}