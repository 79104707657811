import axios from '@/plugins/axios';

export const emails_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_emails_request(state){

            state.pending = true;

        },
        fetch_emails_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.emails.data;

            }
            if(payload.loadData == 'more'){

                payload.emails.data.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.emails.current_page;
            state.data.last_page = payload.emails.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_emails_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        add_email_list_item(state,payload){

            state.data.data.unshift(payload);
            
        },
        update_email_list_item(state,payload){

            state.data.data[payload.index] = payload.updatedGroup;

        },
        delete_email_list_item(state,payload){

            state.data.data.splice(payload,1);

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = '';

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchStoreEmailsRequest({commit},{size, page, loadData}){
            
            commit('fetch_emails_request');

            try{
                
                let res = null

                if(this.state.emails_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/crm/email/all',{
                        params:{
                            search: this.state.emails_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    });

                }else{

                    res = await axios.get('/crm/email/all',{
                        params:{
                            offset: size,
                            page: page,
                        }
                    });

                }

                if(res.data.status_code == '1480'){

                    res.data.loadData = loadData;

                    commit('fetch_emails_success', res.data);

                }

            }catch(ex){

                commit('fetch_emails_failure', {error: ex, message: ex});

            }
        },

    },
    getters:{
        emails(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}