import axios from '../../plugins/axios';
export const product_bundles_module = {
    state: ()=> ({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            filter: {
                tags: [],
                status: [],
                searchedTerm: "",
            },
            allFilters:[],
            query: '',
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_product_bundles_request(state){

            state.pending = true;
            
        },
        fetch_product_bundles_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.product_bundles.data.map((listedBundle) => {

                    listedBundle.bundle_items = listedBundle.bundle_items.map((listedItem) => {

                        listedItem.item_details = JSON.parse(listedItem.item_details);

                        return listedItem;

                    });

                    listedBundle.tags = listedBundle.tags.map(tag=>{

                        return tag.name;

                    });

                    listedBundle.keywords = listedBundle.keywords.map(keyword=>{

                        return keyword.name;

                    });

                    return listedBundle;

                });

            }

            if(payload.loadData == 'more'){

                let moreBundles = payload.product_bundles.data.map((listedBundle) => {

                    listedBundle.bundle_items = listedBundle.bundle_items.map((listedItem) => {

                        listedItem.item_details = JSON.parse(listedItem.item_details);

                        return listedItem;

                    });

                    listedBundle.tags = listedBundle.tags.map(tag=>{

                        return tag.name;

                    });

                    listedBundle.keywords = listedBundle.keywords.map(keyword=>{

                        return keyword.name;

                    });

                    return listedBundle;

                });

                moreBundles.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.product_bundles.current_page;
            state.data.last_page = payload.product_bundles.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_product_bundles_failure(state, payload){

            state.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_product_bundles_list(state,payload){

            payload.bundle_items = payload.bundle_items.map((listedItem) => {

                listedItem.item_details = JSON.parse(listedItem.item_details);

                return listedItem;

            });

            payload.tags = payload.tags.map((tag) => {

                return tag.name;

            });

            payload.keywords = payload.keywords.map((keyword) => {

                return keyword.name;

            });

            state.data.data.unshift(payload);
            
        },
        update_product_bundles_list(state,payload){

            if(payload.field == "status"){

                state.data.data[payload.recordIndex].isActive = payload.productBundle.isActive

            }else if(payload.field == "name"){
             
                state.data.data[payload.recordIndex].name = payload.productBundle.name
                state.data.data[payload.recordIndex].slug = payload.productBundle.slug

            }else{

                state.data.data[payload.recordIndex] = payload.productBundle;

            }

        },
        find_and_update_product_bundle(state,payload){

            let index = state.data.data.findIndex((dataItem) => dataItem.id == payload.productBundle.id);

            if(index != -1){

                state.data.data[index] = payload.productBundle

            }

        },
        delete_product_bundles_list(state,payload){

            state.data.data.splice(payload,1);

        },
        product_bundles_bulk_action(state,payload){
            
            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((bundle)=>{

                    if(!payload.listIds.includes(bundle.id)){
                        
                        return bundle

                    }

                });

            }else if(payload.action == 'statusChange'){

                state.data.data.forEach((bundle) =>{

                    if(payload.listIds.includes(bundle.id)){

                        bundle.isActive = payload.status

                    }

                });

            }
            
        },
        update_product_bundle_filters(state,payload){
        
            state.data.filter.status = payload.status;
            state.data.filter.tags = payload.tags;
            state.data.query = ''

            state.data.filter.searchedTerm = payload.searchedTerm;

            let tags = state.data.filter.tags

            if(tags.length > 0){

                for(let i = 0; i < tags.length ; i++){

                    let arr = ''

                    if(state.data.query.includes("?")){

                        arr = "&tags["+i+"]"+"="+tags[i]

                    }else{

                        arr = "tags["+i+"]"+"="+tags[i]

                    }

                    if(state.data.query.includes("?")){

                        state.data.query = state.data.query + arr

                    }else{

                        state.data.query = '?'+state.data.query + arr

                    }

                }

            }

            if(state.data.filter.status.length > 0){

                if(state.data.query.includes('?')){

                    state.data.query = state.data.query + "&status=" + state.data.filter.status

                }else{

                    state.data.query = state.data.query + "?status=" + state.data.filter.status

                }

            }

            if(state.data.filter.searchedTerm){

                if(state.data.query.includes('?')){

                    state.data.query = state.data.query + "&search="+ state.data.filter.searchedTerm

                }else{

                    state.data.query = state.data.query + "?search="+ state.data.filter.searchedTerm

                }

            }

        },
        update_product_bundle_allFilters(state,payload){

            state.data.allFilters = payload

        },
        remove_product_bundle_filters(state){

            state.data.filter = {
                tags: [],
                status: [],
                searchedTerm: "",
            }

            state.data.query = ''
            state.data.allFilters = []
            
        },
        remove_product_bundle_search(state){

            state.data.filter.searchedTerm = ''

            state.data.query = ''
            state.data.allFilters = []

        },
        update_searched_keyword(state,payload){

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''
            
        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.filter = {
                tags: [],
                status: [],
                searchedTerm: "",
            }

            state.data.current_page = 1
            state.data.data = []
            state.data.last_page = null,
            state.data.searchedKeyword = ''

        }
    },
    actions: {
        async fetchProductBundlesRequest({commit},{size, page, loadData}){

            commit('fetch_product_bundles_request');
            try{
                let res = null;

                if(this.state.product_bundles_module.data.query.length > 0){
                
                    if(this.state.product_bundles_module.data.query.indexOf("?") == 0){
                    
                        res = await axios.get(`/product-bundle/all${this.state.product_bundles_module.data.query}`,{
                            params:{
                                offset: size,
                                page: page
                            }
                        });
                    
                    }else{

                        res = await axios.get(`/product-bundle/all?${this.state.product_bundles_module.data.query}`,{
                            params:{
                                offset: size,
                                page: page
                            }
                        });

                    }

                }else{

                    res = await axios.get(`/product-bundle/all`,{
                        params:{
                            offset: size,
                            page: page
                        }
                    });

                }
                if(res.data.status_code == '1761'){

                    res.data.loadData = loadData

                    commit('fetch_product_bundles_success', res.data);

                }
            }catch(ex){

                commit('fetch_product_bundles_failure', {error: ex, message: ex});

            }


            // commit('fetch_product_bundles_request');
            // try{
            //     let res = null;
            //     if(this.state.product_bundles_module.data.searchedKeyword.length > 0){
    
            //         res = await axios.get('/product-bundle/all',{
            //             params:{
            //                 search: this.state.product_bundles_module.data.searchedKeyword,
            //                 offset: size,
            //                 page: page
            //             }
            //         });

            //     }else{

            //         res = await axios.get('/product-bundle/all',{
            //             params:{
            //                 offset: size,
            //                 page: page
            //             }
            //         });

            //     }
    
            //     if(res.data.status_code == '1761'){

            //         res.data.loadData = loadData

            //         commit('fetch_product_bundles_success', res.data);

            //     }
            // }catch(ex){

            //     commit('fetch_product_bundles_failure', {error: ex, message: ex});

            // }

        }
    },
    getters: {
        product_bundles(state){

            return state.data.data;

        },
        filters(state){

            return state.data.filter;

        },
        allFilters(state){

            return state.data.allFilters;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;

        },
        error:(state)=>state.error
    },


}